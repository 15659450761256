<script lang="ts">
    import {
        Button,
        Header,
        HeaderNav,
        HeaderNavItem,
        HeaderUtilities,
        Loading,
        SideNav,
        SideNavItems,
        SideNavLink,
        SkipToContent,
    } from "carbon-components-svelte";
    import { Router, Route, navigate } from "svelte-navigator";
    import CreateGiftCard, { createGiftCardState } from "./modals/CreateGiftCard.svelte";
    import CreditGiftCard, { creditGiftCardState } from "./modals/CreditGiftCard.svelte";
    import DebitGiftCard, { debitGiftCardState } from "./modals/DebitGiftCard.svelte";
    import RemoveGiftCard, { removeGiftCardState } from "./modals/RemoveGiftCard.svelte";
    import GiftCards from "./views/GiftCards.svelte";
    import Orders from "./views/Orders.svelte";
    import DeliveryCalendar from "./views/DeliveryCalendar.svelte";
    import { createAuth, isAuthenticated, isLoading } from "./auth";
    import Settings from "./views/Settings.svelte";
    import { Settings as LuxonSettings } from "luxon";

    import CreateDeliveryDate, {
        createDeliveryDateState,
    } from "./modals/CreateDeliveryDate.svelte";
    import RemoveDeliveryDate, {
        removeDeliveryDateState,
    } from "./modals/RemoveDeliveryDate.svelte";

    LuxonSettings.defaultZoneName = "Europe/Helsinki";

    $: if (LuxonSettings.defaultZoneName !== "Europe/Helsinki") {
        console.error("Error setting timezone to Europe/Helisnki");
    }

    const config = {
        domain: process.env.AUTH0_DOMAIN,
        client_id: process.env.AUTH0_CLIENT_ID,
        audience: process.env.AUTH0_AUDIENCE,
    };

    const { login, logout } = createAuth(config);

    let isSideNavOpen = false;

    function _navigate(slug: string) {
        navigate(slug);
        if (isSideNavOpen) {
            isSideNavOpen = false;
        }
    }
</script>

<Router>
    {#if $isLoading}
        <Loading small />
    {:else if !$isAuthenticated}
        <Header company="Eden Flowers" platformName="Dashboard">
            <HeaderUtilities>
                <Button on:click={login}>Log in</Button>
            </HeaderUtilities>
        </Header>
    {:else}
        <CreateGiftCard bind:open={$createGiftCardState.open} />
        <RemoveGiftCard bind:id={$removeGiftCardState.id} bind:open={$removeGiftCardState.open} />
        <DebitGiftCard bind:id={$debitGiftCardState.id} bind:open={$debitGiftCardState.open} />
        <CreditGiftCard bind:id={$creditGiftCardState.id} bind:open={$creditGiftCardState.open} />
        <CreateDeliveryDate bind:open={$createDeliveryDateState.open} />
        <RemoveDeliveryDate bind:state={$removeDeliveryDateState} />

        <Header
            persistentHamburgerMenu={true}
            company="Eden Flowers"
            platformName="Dashboard"
            bind:isSideNavOpen
        >
            <div slot="skip-to-content">
                <SkipToContent />
            </div>

            <HeaderNav>
                <HeaderNavItem on:click={() => navigate("/orders")} text="Orders" />
                <HeaderNavItem on:click={() => navigate("/giftcards")} text="Gift cards" />
                <HeaderNavItem
                    on:click={() => navigate("/deliverycalendar")}
                    text="Delivery calendar"
                />
                <HeaderNavItem on:click={() => navigate("/settings")} text="Settings" />
            </HeaderNav>
            <SideNav bind:isOpen={isSideNavOpen}>
                <SideNavItems>
                    <SideNavLink on:click={() => _navigate("/orders")} text="Orders" />
                    <SideNavLink on:click={() => _navigate("/giftcards")} text="Gift cards" />
                    <SideNavLink
                        on:click={() => _navigate("/deliverycalendar")}
                        text="Delivery calendar"
                    />
                    <SideNavLink on:click={() => _navigate("/settings")} text="Settings" />
                </SideNavItems>
            </SideNav>

            <HeaderUtilities>
                <Button on:click={logout}>Log out</Button>
            </HeaderUtilities>
        </Header>

        <Route path="/orders">
            <Orders />
        </Route>

        <Route path="/giftcards">
            <GiftCards />
        </Route>

        <Route path="/deliverycalendar">
            <DeliveryCalendar />
        </Route>

        <Route path="/settings">
            <Settings />
        </Route>
    {/if}
</Router>
