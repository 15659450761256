import { fetcher } from "@edenflowers/common";
import { writable } from "svelte/store";
import { authToken } from "../auth";
import utils from "../utils";
export const giftCardsStore = writable([]);
export async function loadGiftCards() {
    const res = await fetcher({
        url: process.env.SERVER_URL + "/giftcard",
        method: "GET",
        opts: {
            headers: {
                Authorization: "Bearer " + utils.getAuthToken(authToken),
            },
        },
    });
    if (res.ok) {
        giftCardsStore.set(res.val.data);
    }
}
