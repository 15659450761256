<script lang="ts">
    import { giftCardsStore } from "../dataStores/giftCards";
    import utils from "../utils";

    export let id = "";

    $: giftCard = $giftCardsStore.find((n) => n.id === id);
</script>

{#if giftCard}
    <div class="flex flex-row py-8 gap-20">
        <div>
            <h6 class="mb-4">Transactions</h6>
            {#each giftCard.transactions
                .slice()
                .sort(
                    (a, b) => new Date(b.createdAt).getTime() + new Date(a.createdAt).getTime()
                ) as t}
                <div class="flex flex-col mb-4 space-y-1">
                    <div class="font-black">
                        {#if t.debitAmount}
                            <span class="text-red-500 font-extrabold">{"- "}</span>
                            {utils.localise.currency(t.debitAmount / 100)}
                        {:else}
                            <span class="text-green-500 font-extrabold">{"+ "}</span>
                            {utils.localise.currency(
                                t.debitAmount ? t.debitAmount / 100 : t.creditAmount / 100
                            )}
                        {/if}
                    </div>
                    <div>
                        {utils.localise.date(t.createdAt, {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                        })}
                    </div>
                </div>
            {/each}
        </div>

        <div>
            <h6 class="mb-4">Customer</h6>
            {#if giftCard.customer}
                <div class="space-y-1 flex flex-col">
                    <span>Name: {giftCard.customer.firstName} {giftCard.customer.lastName}</span>
                    <span>Email: {giftCard.customer.email}</span>
                </div>
            {:else}
                No information
            {/if}
        </div>
    </div>
{/if}
