<script context="module">
    import { writable } from "svelte/store";

    export const debitGiftCardState = writable({ open: false, id: "" });
</script>

<script lang="ts">
    import { Form, Modal, NumberInput } from "carbon-components-svelte";
    import utils from "../utils";
    import { fetcher } from "@edenflowers/common";
    import type { GiftCardTypes } from "@edenflowers/server";
    import { giftCardsStore, loadGiftCards } from "../dataStores/giftCards";
    import ServerErrorMessage from "../components/ServerErrorMessage.svelte";
    import { authToken } from "../auth";

    export let open = false;
    export let id = "";

    let value = 0;

    $: giftCard = $giftCardsStore.find((n) => n.id === id);

    let errors: { [k: string]: boolean } = {};

    function validate() {
        errors = {};

        if (value < 1) errors.value = true;
    }

    async function handleSubmit() {
        validate();

        if (Object.entries(errors).length === 0) {
            const res = await fetcher<
                GiftCardTypes.DebitGiftCardResBody,
                GiftCardTypes.DebitGiftCardReqBody
            >({
                url: `${process.env.SERVER_URL}/giftcard/debit`,
                method: "POST",
                reqObject: {
                    id,
                    amount: value * 100,
                },
                opts: {
                    headers: {
                        Authorization: "Bearer " + $authToken,
                    },
                },
            });

            if (res.err) {
                console.error(res.val);
                errors.server = true;
            }

            if (res.ok) {
                await loadGiftCards();
                open = false;
            }
        }
    }
</script>

<Modal
    id="debitgiftcard-modal"
    primaryButtonText={`Debit ${utils.localise.currency(value)}`}
    secondaryButtonText="Cancel"
    size="sm"
    modalHeading="Debit gift card"
    on:submit={handleSubmit}
    on:click:button--secondary={() => {
        open = false;
    }}
    on:close={() => {
        setTimeout(() => {
            // Reset state
            errors = {};
            id = "";

            // Reset form
            value = 0;
        }, 250);
    }}
    bind:open
>
    {#if giftCard}
        <div class="my-4">
            {`The current balance is ${utils.localise.currency(giftCard.balance / 100)}`}
        </div>
    {/if}

    <Form>
        <NumberInput
            invalid={errors.value}
            invalidText="Value should be greater than 0"
            min={0}
            label="Debit"
            bind:value
        />
    </Form>

    <ServerErrorMessage bind:show={errors.server} />
</Modal>
