import { localStore } from "@edenflowers/common";
export const dateFormats = [
    {
        id: "DATE_SHORT",
        value: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
    },
    {
        id: "DATE_MED",
        value: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
    },
];
export const settingsStore = localStore("settings", {
    dateFormat: dateFormats[0],
});
