<script lang="ts">
    import { Content, Dropdown } from "carbon-components-svelte";
    import { dateFormats, settingsStore } from "../settings";
    import utils from "../utils";

    function handleDateFormatSelect(event) {
        const { id } = event.detail.selectedItem;

        settingsStore.update((store) => {
            return {
                ...store,
                dateFormat: dateFormats.find((format) => format.id === id),
            };
        });
    }
</script>

<Content class="bg-transparent">
    <div class="space-y-4 max-w-xs">
        <h1>Settings</h1>

        <Dropdown
            on:select={handleDateFormatSelect}
            type="inline"
            titleText="Date format"
            selectedIndex={dateFormats.findIndex(
                (format) => $settingsStore.dateFormat.id === format.id
            )}
            items={dateFormats.map(({ id, value }) => {
                return {
                    id,
                    text: utils.localise.date(new Date(), value),
                };
            })}
        />
    </div>
</Content>
