<script lang="ts">
    import {
        Button,
        Content,
        DataTable,
        DataTableSkeleton,
        OverflowMenu,
        OverflowMenuItem,
        Toolbar,
        ToolbarBatchActions,
        ToolbarContent,
    } from "carbon-components-svelte";
    import { onMount } from "svelte";
    import { deliveryDatesStore, loadDeliveryDates } from "../dataStores/deliveryDates";
    import { DateTime } from "luxon";
    import { createDeliveryDateState } from "../modals/CreateDeliveryDate.svelte";
    import utils from "../utils";
    import TrashCan16 from "carbon-icons-svelte/lib/TrashCan16";
    import { removeDeliveryDateState } from "../modals/RemoveDeliveryDate.svelte";
    import { settingsStore } from "../settings";

    onMount(async () => {
        await loadDeliveryDates();
    });

    function formatTimeWindow(start: Date | string, end: Date | string): string {
        return (
            utils.localise.date(start, DateTime.TIME_24_SIMPLE) +
            " - " +
            utils.localise.date(end, DateTime.TIME_24_SIMPLE)
        );
    }

    let headers = [
        { key: "date", value: "Date" },
        { key: "delivery", value: "Delivery" },
        { key: "deliveryWindow", value: "Delivery window" },
        { key: "orderWindow", value: "Order window" },
        { key: "overflow", empty: true },
    ];
    let rows: {
        id: string;
        delivery: string;
        date: string;
        deliveryWindow: string;
        orderWindow: string;
    }[] = [];

    $: if ($deliveryDatesStore) {
        rows = $deliveryDatesStore.map(
            ({
                id,
                date,
                deliver,
                deliveryWindowStart,
                deliveryWindowEnd,
                orderWindowStart,
                orderWindowEnd,
            }) => {
                return {
                    id,
                    delivery: deliver ? "On" : "Off",
                    date: utils.localise.date(date, $settingsStore.dateFormat.value),
                    deliveryWindow:
                        deliveryWindowStart && deliveryWindowEnd
                            ? formatTimeWindow(deliveryWindowStart, deliveryWindowEnd)
                            : "–",
                    orderWindow:
                        orderWindowStart && orderWindowEnd
                            ? formatTimeWindow(orderWindowStart, orderWindowEnd)
                            : "–",
                };
            }
        );
    }
</script>

<Content class="bg-transparent">
    <div class="space-y-6">
        <h1>Dates</h1>

        {#if !$deliveryDatesStore}
            <DataTableSkeleton showHeader={false} showToolbar={false} />
        {:else}
            <DataTable
                batchSelection
                bind:selectedRowIds={$removeDeliveryDateState.removeMany}
                sortable
                headers={headers}
                rows={rows}
            >
                <Toolbar>
                    <ToolbarBatchActions>
                        <Button
                            on:click={() => {
                                removeDeliveryDateState.update((store) => {
                                    return {
                                        ...store,
                                        open: true,
                                    };
                                });
                            }}
                            icon={TrashCan16}>Remove selected</Button
                        >
                    </ToolbarBatchActions>

                    <ToolbarContent>
                        <Button
                            on:click={() => {
                                createDeliveryDateState.set({ open: true });
                            }}>Add date</Button
                        >
                    </ToolbarContent>
                </Toolbar>

                <span slot="cell" let:cell let:row>
                    {#if cell.key === "overflow"}
                        <OverflowMenu flipped>
                            <OverflowMenuItem on:click={() => {}} text="Edit" />
                            <OverflowMenuItem
                                danger
                                on:click={() => {
                                    removeDeliveryDateState.set({
                                        removeMany: [],
                                        removeOne: [row.id],
                                        open: true,
                                    });
                                }}
                                text="Remove"
                            />
                        </OverflowMenu>
                    {:else}{cell.value}{/if}
                </span>
            </DataTable>
        {/if}
    </div>
</Content>
