<script context="module">
    import { writable } from "svelte/store";

    export const createGiftCardState = writable({ open: false });
</script>

<script lang="ts">
    import {
        Checkbox,
        Form,
        ModalFooter,
        TextInput,
        ComposedModal,
        ModalBody,
        ModalHeader,
        Slider,
    } from "carbon-components-svelte";
    import { fetcher } from "@edenflowers/common";
    import type { GiftCardTypes } from "@edenflowers/server";
    import { loadGiftCards } from "../dataStores/giftCards";
    import ServerErrorMessage from "../components/ServerErrorMessage.svelte";
    import { authToken } from "../auth";

    export let open = false;

    let includeCustomerInfo = false;
    let firstName = "";
    let lastName = "";
    let email = "";
    let value = 50;

    let errors: { [k: string]: boolean } = {};

    function validate() {
        errors = {};

        if (includeCustomerInfo) {
            if (!firstName) errors.firstName = true;
            if (!lastName) errors.lastName = true;
            if (!email) errors.email = true;
        }

        if (value < 1) errors.value = true;
    }

    async function handleSubmit() {
        validate();

        if (Object.keys(errors).length === 0) {
            const res = await fetcher<
                GiftCardTypes.CreateGiftCardResBody,
                GiftCardTypes.CreateGiftCardReqBody
            >({
                url: `${process.env.SERVER_URL}/giftcard`,
                method: "POST",
                reqObject: {
                    customer: {
                        firstName,
                        lastName,
                        email,
                    },
                    value: value * 100,
                },
                opts: {
                    headers: {
                        Authorization: "Bearer " + $authToken,
                    },
                },
            });

            if (res.err) {
                console.error(res.val);
                errors.server = true;
            }

            if (res.ok) {
                open = false;
                await loadGiftCards();
            }
        }
    }
</script>

<ComposedModal
    on:submit={handleSubmit}
    bind:open
    on:close={() => {
        setTimeout(() => {
            // Reset state
            errors = {};

            // Reset form
            includeCustomerInfo = false;
            firstName = "";
            lastName = "";
            email = "";
            value = 50;
        }, 250);
    }}
    size="sm"
>
    <ModalHeader title="Create gift card" />

    <ModalBody hasForm>
        <Form class="my-2 space-y-6">
            <div>
                <Checkbox labelText="Inlcude customer info" bind:checked={includeCustomerInfo} />
            </div>

            {#if includeCustomerInfo}
                <div>
                    <TextInput
                        invalid={errors.firstName}
                        invalidText="Missing first name"
                        inline
                        type="text"
                        labelText="First name"
                        bind:value={firstName}
                    />
                    <TextInput
                        invalid={errors.lastName}
                        invalidText="Missing last name"
                        inline
                        type="text"
                        labelText="Last name"
                        bind:value={lastName}
                    />
                    <TextInput
                        invalid={errors.email}
                        invalidText="Missing email address"
                        inline
                        type="email"
                        labelText="Email address"
                        bind:value={email}
                    />
                </div>
            {/if}

            <Slider labelText="Value" min={5} max={150} maxLabel="150 €" bind:value step={5} />
        </Form>
    </ModalBody>

    <ServerErrorMessage bind:show={errors.server} />

    <ModalFooter secondaryButtonText="Cancel" primaryButtonText="Create" />
</ComposedModal>
