import { DateTime } from "luxon";
export default {
    getAuthToken: (store) => {
        let token = "";
        const unsubscribe = store.subscribe((n) => {
            token = n;
        });
        unsubscribe();
        return token;
    },
    localise: {
        currency: (amount, locale = "sv-FI") => {
            return new Intl.NumberFormat(locale, {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
            }).format(amount);
        },
        date: (date, format, locale = "sv-FI") => {
            const dt = DateTime.fromJSDate(new Date(date));
            return dt.setLocale(locale).toLocaleString(format);
        },
    },
};
