<script lang="ts">
    import { InlineNotification } from "carbon-components-svelte";

    export let show = false;
</script>

{#if show}
    <InlineNotification
        title="A server error occured"
        subtitle="Check the logs."
        kind="error"
        hideCloseButton
    />
{/if}
