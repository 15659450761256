<script lang="ts" context="module">
    import { writable } from "svelte/store";

    type removeDeliveryDateState = {
        open: boolean;
        removeMany: string[];
        removeOne: string[];
    };

    export const removeDeliveryDateState = writable<removeDeliveryDateState>({
        open: false,
        removeMany: [],
        removeOne: [],
    });
</script>

<script lang="ts">
    import { fetcher } from "@edenflowers/common";
    import { Modal } from "carbon-components-svelte";
    import ServerErrorMessage from "../components/ServerErrorMessage.svelte";
    import { authToken } from "../auth";
    import { loadDeliveryDates } from "../dataStores/deliveryDates";

    let defaultState = {
        open: false,
        removeMany: [],
        removeOne: [],
    };

    export let state: removeDeliveryDateState = defaultState;

    let id = [];
    $: {
        if (state.removeMany.length > 0) {
            id = state.removeMany;
        } else {
            id = state.removeOne;
        }
    }

    let errors: { [k: string]: boolean } = {};
    function validate() {
        errors = {};
    }

    async function handleSubmit() {
        validate();

        if (Object.entries(errors).length === 0) {
            const res = await fetcher({
                url: `${process.env.SERVER_URL}/delivery/date/${id.join(",")}`,
                method: "DELETE",
                opts: {
                    headers: {
                        Authorization: "Bearer " + $authToken,
                    },
                },
            });

            if (res.err) {
                console.error(res.val);
                errors.server = true;
            }

            if (res.ok) {
                setTimeout(async () => {
                    await loadDeliveryDates();
                }, 250);
                state.open = false;
            }
        }
    }
</script>

<Modal
    size="sm"
    danger
    modalHeading={`Remove delivery date${id.length > 1 ? "s" : ""}`}
    primaryButtonText="Confirm"
    secondaryButtonText="Cancel"
    bind:open={state.open}
    on:submit={handleSubmit}
    on:click:button--secondary={() => {
        state.open = false;
    }}
    on:close={() => {
        setTimeout(() => {
            state = defaultState;
        }, 250);
    }}
>
    <div>
        {`Are you sure you want to delete ${id.length} delivery date${id.length > 1 ? "s" : ""}?`}
    </div>

    <ServerErrorMessage bind:show={errors.server} />
</Modal>
