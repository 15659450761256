<script lang="ts">
    import {
        Button,
        Content,
        DataTable,
        DataTableSkeleton,
        OverflowMenu,
        OverflowMenuItem,
        Toolbar,
        ToolbarContent,
        ToolbarSearch,
    } from "carbon-components-svelte";
    import { onMount } from "svelte";
    import GiftCardInfo from "../components/GiftCardInfo.svelte";
    import { giftCardsStore, loadGiftCards } from "../dataStores/giftCards";
    import { createGiftCardState } from "../modals/CreateGiftCard.svelte";
    import { creditGiftCardState } from "../modals/CreditGiftCard.svelte";
    import { debitGiftCardState } from "../modals/DebitGiftCard.svelte";
    import { removeGiftCardState } from "../modals/RemoveGiftCard.svelte";
    import utils from "../utils";
    import { settingsStore } from "../settings";

    onMount(async () => {
        await loadGiftCards();
    });

    let headers = [
        { key: "code", value: "Code" },
        { key: "customer", value: "Customer" },
        { key: "expiryDate", value: "Expiry date" },
        { key: "purchaseDate", value: "Purchase date" },
        { key: "balance", value: "Balance" },
        { key: "overflow", empty: true },
    ];
    let rows: {
        id: string;
        code: string;
        customer: string;
        expiryDate: string;
        purchaseDate: string;
        balance: string;
    }[] = [];

    $: if ($giftCardsStore) {
        rows = $giftCardsStore.map(({ id, code, customer, expiryDate, purchaseDate, balance }) => {
            return {
                id,
                code,
                customer: customer ? `${customer?.firstName} ${customer?.lastName}` : "–",
                expiryDate: utils.localise.date(expiryDate, $settingsStore.dateFormat.value),
                purchaseDate: utils.localise.date(purchaseDate, $settingsStore.dateFormat.value),
                balance: utils.localise.currency(balance / 100),
            };
        });
    }
</script>

<Content class="bg-transparent">
    <div class="space-y-6">
        <h1>Gift cards</h1>

        {#if !$giftCardsStore}
            <DataTableSkeleton showHeader={false} showToolbar={false} />
        {:else}
            <DataTable expandable sortable headers={headers} rows={rows}>
                <Toolbar>
                    <ToolbarContent>
                        <ToolbarSearch />
                        <Button
                            on:click={() => {
                                createGiftCardState.update((s) => {
                                    return { ...s, open: true };
                                });
                            }}
                        >
                            Create gift card
                        </Button>
                    </ToolbarContent>
                </Toolbar>

                <span slot="cell" let:cell let:row>
                    {#if cell.key === "overflow"}
                        <OverflowMenu flipped>
                            <OverflowMenuItem
                                on:click={() => {
                                    debitGiftCardState.update((s) => {
                                        return { ...s, open: true, id: row.id };
                                    });
                                }}
                                text="Debit"
                            />
                            <OverflowMenuItem
                                on:click={() => {
                                    creditGiftCardState.update((s) => {
                                        return { ...s, open: true, id: row.id };
                                    });
                                }}
                                text="Credit"
                            />
                            <OverflowMenuItem
                                on:click={() => {
                                    removeGiftCardState.update((s) => {
                                        return { ...s, open: true, id: row.id };
                                    });
                                }}
                                danger
                                text="Remove"
                            />
                        </OverflowMenu>
                    {:else}{cell.value}{/if}
                </span>

                <div slot="expanded-row" let:row>
                    <GiftCardInfo id={row.id} />
                </div>
            </DataTable>
        {/if}
    </div>
</Content>
