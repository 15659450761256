<script context="module">
    import { writable } from "svelte/store";

    export const removeGiftCardState = writable({ open: false, id: "" });
</script>

<script lang="ts">
    import { fetcher } from "@edenflowers/common";
    import { Modal } from "carbon-components-svelte";
    import ServerErrorMessage from "../components/ServerErrorMessage.svelte";
    import { giftCardsStore, loadGiftCards } from "../dataStores/giftCards";
    import { authToken } from "../auth";

    export let open = false;
    export let id = "";

    $: giftCard = $giftCardsStore.find((n) => n.id === id);

    let errors: { [k: string]: boolean } = {};
    function validate() {
        errors = {};
    }

    async function handleSubmit() {
        validate();

        if (Object.entries(errors).length === 0) {
            const res = await fetcher({
                url: `${process.env.SERVER_URL}/giftcard/${id}`,
                method: "DELETE",
                opts: {
                    headers: {
                        Authorization: "Bearer " + $authToken,
                    },
                },
            });

            if (res.err) {
                console.error(res.val);
                errors.server = true;
            }

            if (res.ok) {
                setTimeout(async () => {
                    await loadGiftCards();
                }, 250);
                open = false;
            }
        }
    }
</script>

<Modal
    size="sm"
    danger
    modalHeading="Remove gift card"
    primaryButtonText="Confirm"
    secondaryButtonText="Cancel"
    bind:open
    on:submit={handleSubmit}
    on:click:button--secondary={() => {
        open = false;
    }}
    on:close={() => {
        setTimeout(() => {
            // Reset
            id = "";
        }, 250);
    }}
>
    {#if giftCard}
        <div class="font-bold my-4">{giftCard.code}</div>
    {/if}

    <div>{`Are you sure you want to delete this gift card?`}</div>

    <ServerErrorMessage bind:show={errors.server} />
</Modal>
