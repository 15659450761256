// Use CDN due to bundling error
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import createAuth0Client from "@auth0/auth0-spa-js";
import { onMount } from "svelte";
import { writable } from "svelte/store";
export const isLoading = writable(true);
export const isAuthenticated = writable(false);
export const authToken = writable("");
export const userInfo = writable(null);
export const authError = writable(null);
// Default Auth0 expiration time is 10 hours or something like that.
// If you want to get fancy you can parse the JWT token and get
// token's actual expiration time.
const refreshRate = 10 * 60 * 60 * 1000;
export function createAuth(config) {
    let auth0 = null;
    let intervalId = undefined;
    onMount(async () => {
        auth0 = await createAuth0Client(config);
        const params = new URLSearchParams(window.location.search);
        // Check if something went wrong during login redirect
        // and extract the error message
        if (params.has("error")) {
            authError.set(new Error(params.get("error_description")));
        }
        // If `code` then login success
        if (params.has("code")) {
            // Let the Auth0 SDK do it's stuff - save some state, etc.
            await auth0.handleRedirectCallback();
            // Can be smart here and redirect to original path instead of root
            window.history.replaceState({}, document.title, "/");
            authError.set(null);
        }
        const _isAuthenticated = await auth0.isAuthenticated();
        isAuthenticated.set(_isAuthenticated);
        if (_isAuthenticated) {
            userInfo.set(await auth0.getUser());
            const token = await auth0.getTokenSilently();
            authToken.set(token);
            // Refresh token after specific period or things will stop
            // working. Useful for long-lived apps like dashboards.
            intervalId = setInterval(async () => {
                authToken.set(await auth0.getTokenSilently());
            }, refreshRate);
        }
        isLoading.set(false);
        // Clear token refresh interval on component unmount
        return () => {
            intervalId && clearInterval(intervalId);
        };
    });
    const login = async () => {
        await auth0.loginWithRedirect({
            redirect_uri: window.location.origin,
        });
    };
    const logout = () => {
        auth0.logout({
            returnTo: window.location.origin,
        });
    };
    const auth = {
        login,
        logout,
    };
    return auth;
}
