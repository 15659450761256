<script context="module">
    import { writable } from "svelte/store";

    export const createDeliveryDateState = writable({ open: false });
</script>

<script lang="ts">
    import {
        Form,
        ModalFooter,
        ComposedModal,
        ModalBody,
        ModalHeader,
        TimePicker,
        DatePicker,
        DatePickerInput,
        Toggle,
    } from "carbon-components-svelte";
    import { fetcher } from "@edenflowers/common";
    import type { DeliveryTypes } from "@edenflowers/server";
    import ServerErrorMessage from "../components/ServerErrorMessage.svelte";
    import { authToken } from "../auth";
    import { DateTime } from "luxon";
    import { loadDeliveryDates } from "../dataStores/deliveryDates";

    export let open = false;

    let deliver = false;
    let customOrderWindow = false;
    let customDeliveryWindow = false;
    let orderWindowStart = "";
    let orderWindowEnd = "";
    let deliveryWindowStart = "";
    let deliveryWindowEnd = "";

    // Child toggles should be toggled off when `deliver` is toggled off
    $: {
        if (!deliver) {
            customOrderWindow = false;
            customDeliveryWindow = false;
        }
    }

    // Reset order window inputs when toggled off
    $: {
        if (!customOrderWindow) {
            orderWindowStart = "";
            orderWindowEnd = "";
        }
    }

    // Reset delivery window inputs when toggled off
    $: {
        if (!customDeliveryWindow) {
            deliveryWindowStart = "";
            deliveryWindowEnd = "";
        }
    }

    // Split the DatePicker string (22/02/21) in order to create a DateTime object
    let date: DateTime | undefined;
    let datePickerValue: string = "";
    $: {
        if (datePickerValue) {
            const d: string[] = datePickerValue.split("/");
            date = DateTime.local(Number(d[2]), Number(d[1]), Number(d[0])).startOf("day");
        }
    }

    let errors: { [k: string]: boolean } = {};

    function mergeDateTime(date: DateTime, time: string): Date | undefined {
        if (!time) return;

        const t = time.split(":");
        return date.set({ hour: Number(t[0]), minute: Number(t[1]) }).toJSDate();
    }

    function validate() {
        errors = {};

        if (!datePickerValue) errors.datePickerValue = true;

        const checkTimeFormat = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

        if (customOrderWindow) {
            if (!checkTimeFormat.exec(orderWindowStart)) errors.orderWindowStart = true;
            if (!checkTimeFormat.exec(orderWindowEnd)) errors.orderWindowEnd = true;
        }

        if (customDeliveryWindow) {
            if (!checkTimeFormat.exec(deliveryWindowStart)) errors.deliveryWindowStart = true;
            if (!checkTimeFormat.exec(deliveryWindowEnd)) errors.deliveryWindowEnd = true;
        }
    }

    async function handleSubmit() {
        validate();

        if (Object.keys(errors).length === 0) {
            const res = await fetcher<unknown, DeliveryTypes.CreateOrUpdateDeliveryDateReqBody>({
                url: `${process.env.SERVER_URL}/delivery/date`,
                method: "POST",
                reqObject: {
                    date: date.toJSDate(),
                    deliver,
                    orderWindowStart: mergeDateTime(date, orderWindowStart),
                    orderWindowEnd: mergeDateTime(date, orderWindowEnd),
                    deliveryWindowStart: mergeDateTime(date, deliveryWindowStart),
                    deliveryWindowEnd: mergeDateTime(date, deliveryWindowEnd),
                },
                shouldParseJSON: false,
                opts: {
                    headers: {
                        Authorization: "Bearer " + $authToken,
                    },
                },
            });

            if (res.err) {
                console.error(res.val);
                errors.server = true;
            }

            if (res.ok) {
                open = false;
                await loadDeliveryDates();
            }
        }
    }
</script>

<ComposedModal
    on:submit={handleSubmit}
    on:close={() => {
        setTimeout(() => {
            // Reset state
            errors = {};
            datePickerValue = "";

            // Reset form
            deliver = false;
            date = undefined;
        }, 250);
    }}
    size="sm"
    bind:open
>
    <ModalHeader title="Add date" />

    <ModalBody hasForm>
        <Form class="my-2 space-y-6">
            <DatePicker
                minDate={DateTime.local().startOf("day").toJSDate()}
                datePickerType="single"
                dateFormat="d/m/Y"
                bind:value={datePickerValue}
            >
                <DatePickerInput
                    invalid={errors.datePickerValue}
                    invalidText="Missing date"
                    labelText="Select date"
                    placeholder="dd/mm/yyyy"
                />
            </DatePicker>

            <Toggle bind:toggled={deliver} labelText="Deliver" />

            {#if deliver}
                <Toggle bind:toggled={customOrderWindow} labelText="Custom order window" />
                {#if customOrderWindow}
                    <div class="flex flex-row">
                        <TimePicker
                            invalid={errors.orderWindowStart}
                            bind:value={orderWindowStart}
                            size="xl"
                            labelText="Order window start"
                            placeholder="00:00"
                        />
                        <TimePicker
                            invalid={errors.orderWindowEnd}
                            bind:value={orderWindowEnd}
                            size="xl"
                            labelText="Order window end"
                            placeholder="14:00"
                        />
                    </div>
                {/if}

                <Toggle bind:toggled={customDeliveryWindow} labelText="Custom delivery window" />

                {#if customDeliveryWindow}
                    <div class="flex flex-row">
                        <TimePicker
                            invalid={errors.deliveryWindowStart}
                            bind:value={deliveryWindowStart}
                            size="xl"
                            labelText="Delivery window start"
                            placeholder="11:00"
                        />
                        <TimePicker
                            invalid={errors.deliveryWindowEnd}
                            bind:value={deliveryWindowEnd}
                            size="xl"
                            labelText="Delivery window end"
                            placeholder="18:00"
                        />
                    </div>
                {/if}
            {/if}
        </Form>
    </ModalBody>

    <ServerErrorMessage bind:show={errors.server} />

    <ModalFooter secondaryButtonText="Cancel" primaryButtonText="Create" />
</ComposedModal>
